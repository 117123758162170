import { InitialStudioConfigurationState, StudioConfigurationState } from "@internal/utils-studio-configuration-core";
import z from "zod";

/**
 * These overrides apply globally to all modes.  These features VCS never wants to show at all.
 */
export const GLOBAL_VCS_CONFIG_MODIFICATIONS: Partial<InitialStudioConfigurationState> = {
    showChangeTemplate: false,
    showChangeSize: false,
    isCompatibleForMailingService: false,
    showDesignPanel: false,
    disableDesignReview: true,
    shouldAllowUserSave: false,
    shouldAllowRenameDesign: false,
    shouldAllowDuplicateDesign: false,
    shouldAllowMyProjects: false
};

export const VCSUrlSchema = z.object({
    mode: z.enum(["admin", "shopper"]),
    brandedProductId: z.union([z.string(), z.null(), z.undefined()]),
    storeId: z.string()
});

export interface CustomVCSData {
    url: z.infer<typeof VCSUrlSchema>;
}

export function applyVCSModeOverrides(
    baseConfig: StudioConfigurationState,
    customConfig?: CustomVCSData
): StudioConfigurationState {
    let vcsModeOverrides: Partial<StudioConfigurationState> = { ...GLOBAL_VCS_CONFIG_MODIFICATIONS };

    /**
     * In addition to the global overrides VCS also wishes to disable features based on the current editor mode
     * and whether this came from a template (branded product)
     */
    if (customConfig) {
        if (customConfig.url.mode === "shopper" && !!customConfig.url.brandedProductId) {
            // restricted mode doesn't support these but there's a bug where shouldShowBackgroundColor isn't used
            vcsModeOverrides = {
                ...vcsModeOverrides,
                shouldShowBackgroundColor: false,
                shouldShowPatterns: false,
                disableFileBrowserDragNDrop: true,
                shouldAllowToChangeFinish: false
            };
        }
        if (customConfig.url.mode === "admin") {
            vcsModeOverrides = {
                ...vcsModeOverrides,
                shouldShowBackgroundColor: true,
                shouldShowPatterns: true,
                disableFileBrowserDragNDrop: false,
                shouldAllowToChangeFinish: true,
                shouldShowText: true,
                shouldShowImages: true,
                shouldShowGraphics: true,
                shouldShowQRCodes: true,
                shouldShowCutline: true
            };
        }
    }

    return { ...baseConfig, ...vcsModeOverrides };
}
